<template>
  <div class="all">
    <div class="top">
      <span>{{ title }}</span>
      <div @click="moreClk" class="more">
        <span>更多</span>
        <img src="~assets/img/industryInfo/more.png" />
      </div>
    </div>
    <div class="content">
      <ul v-if="show">
        <li v-for="item in list" @click="liClick(item)">
          <span><i></i>{{ ftitle(item.title) }}</span>
          <span>{{ fdate(item.create) }}</span>
        </li>
      </ul>
      <Spin v-else fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoList',
  props: {
    title: String,
    list: Array,
    show: Boolean,
  },
  methods: {
    liClick(item) {
      this.$router.push({
        name: 'IndustryDetail',
        query: { id: item.id, page: item.page, subjectId: item.subjectId },
      })
    },
    moreClk() {
      let data = this.list[0]
      this.$store.commit('industry/setCate', data.subjectId)
      this.$router.push({
        name: 'IndustryList',
      })
    },
  },
  computed: {
    ftitle() {
      return (str) => {
        if (str && str.length >= 15) {
          return str.substr(0, 15) + '...'
        } else {
          return str
        }
      }
    },
    fdate() {
      return (str) => {
        return this.$handle.dateFormat(str, 'm.d h:f')
      }
    },
  },
}
</script>

<style scoped>
.all {
  width: 390px;
  height: 410px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #25449a;
}

.top > span {
  display: inline-block;
  width: 116px;
  height: 100%;
  line-height: 50px;
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  background-color: #25449a;
  text-align: center;
}

.more {
  cursor: pointer;
}
.more img {
  width: 11px;
  height: 11px;
  margin-left: 10px;
}

.content {
  position: relative;
  width: 390px;
  height: 330px;
  margin-top: 30px;
}

.content ul {
  width: 390px;
  height: 330px;
  padding: 0 25px;
  border: 1px solid #e6ebf5;
}

.content ul li {
  display: flex;
  justify-content: space-between;
  line-height: 33px;
  font-size: 14px;
  list-style: disc;
  cursor: pointer;
  color: #000;
}

.content ul li:hover {
  color: #25449a;
  text-decoration: underline;
  /* border-bottom: 1px solid #25449a; */
}

.content ul li span:first-child {
  position: relative;
}

.content ul li i {
  position: absolute;
  top: 15px;
  left: -15px;
  font-size: 30px;
  width: 4px;
  height: 4px;
  background-color: #7c7c7c;
  border-radius: 50%;
}
</style>
