<template>
  <div>
    <img-nav />
    <div class="w1400 mrTop50 mrBot175">
      <div class="row">
        <info-list :list="list1" :show="show1" title="基本金属" />
        <info-list :list="list2" :show="show2" title="废旧金属" />
        <info-list :list="list3" :show="show3" title="贵金属&小金属" />
      </div>
      <div class="row mrTop30">
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
      </div>
      <div class="row mrTop30">
        <info-list :list="list4" :show="show4" title="废钢" />
        <info-list :list="list5" :show="show5" title="建材" />
        <info-list :list="list6" :show="show6" title="不锈钢" />
      </div>
      <div class="row mrTop30">
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
      </div>
      <div class="row mrTop30">
        <info-list :list="list7" :show="show7" title="钢坯" />
        <info-list :list="list8" :show="show8" title="铁合金" />
        <info-list :list="list9" :show="show9" title="生铁" />
      </div>
      <div class="row mrTop30">
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
      </div>
      <div class="row mrTop30">
        <info-list :list="list10" :show="show10" title="铁矿石" />
        <info-list :list="list11" :show="show11" title="板材" />
        <info-list :list="list12" :show="show12" title="管材" />
      </div>
      <div class="row mrTop30">
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
      </div>
      <div class="row mrTop30">
        <info-list :list="list13" :show="show13" title="型材" />
        <info-list :list="list14" :show="show14" title="其他" />
        <info-list :list="list15" :show="show15" title="废纸评论" />
      </div>
      <div class="row mrTop30">
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
      </div>
      <div class="row mrTop30">
        <info-list :list="list16" :show="show16" title="废钢钢厂调价" />
        <info-list :list="list17" :show="show17" title="废钢基地调价" />
        <info-list :list="list18" :show="show18" title="废钢新闻" />
      </div>
      <div class="row mrTop30">
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
        <advertisement width="280" />
      </div>
    </div>
  </div>
</template>

<script>
import InfoList from './compontents/layout/InfoList'

import { getAuctionNews } from 'network/IndustryInfo'

export default {
  name: 'IndustryInfo',
  data() {
    return {
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      list7: [],
      list8: [],
      list9: [],
      list10: [],
      list11: [],
      list12: [],
      list13: [],
      list14: [],
      list15: [],
      list16: [],
      list17: [],
      list18: [],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
      show12: false,
      show13: false,
      show14: false,
      show15: false,
      show16: false,
      show17: false,
      show18: false,
    }
  },
  components: {
    InfoList,
  },
  created() {
    let arr = [
      81, // 基本金属
      82, // 废旧金属
      84, // 贵金属&小金属
      85, // 废钢
      86, // 建材
      87, // 不锈钢
      88, // 钢坯
      89, // 铁合金
      90, // 生铁
      91, // 铁矿石
      94, // 板材
      96, // 管材
      97, // 型材
      99, // 其他
      104, // 废纸评论
      107, // 废钢钢厂调价
      108, // 废钢基地调价
      109, // 废钢新闻
    ]
    for (let i = 0; i < arr.length; i++) {
      this.getAuctionNews(arr[i])
    }
  },
  methods: {
    getAuctionNews(num) {
      let data = {
        num: 1,
        size: 10,
        subjectId: num,
      }
      getAuctionNews(data).then((res) => {
        if (res.code == 0) {
          switch (num) {
            case 81:
              this.list1 = res.data
              this.show1 = true
              break
            case 82:
              this.list2 = res.data
              this.show2 = true
              break
            case 84:
              this.list3 = res.data
              this.show3 = true
              break
            case 85:
              this.list4 = res.data
              this.show4 = true
              break
            case 86:
              this.list5 = res.data
              this.show5 = true
              break
            case 87:
              this.list6 = res.data
              this.show6 = true
              break
            case 88:
              this.list7 = res.data
              this.show7 = true
              break
            case 89:
              this.list8 = res.data
              this.show8 = true
              break
            case 90:
              this.list9 = res.data
              this.show9 = true
              break
            case 91:
              this.list10 = res.data
              this.show10 = true
              break
            case 94:
              this.list11 = res.data
              this.show11 = true
              break
            case 96:
              this.list12 = res.data
              this.show12 = true
              break
            case 97:
              this.list13 = res.data
              this.show13 = true
              break
            case 99:
              this.list14 = res.data
              this.show14 = true
              break
            case 104:
              this.list15 = res.data
              this.show15 = true
              break
            case 107:
              this.list16 = res.data
              this.show16 = true
              break
            case 108:
              this.list17 = res.data
              this.show17 = true
              break
            case 109:
              this.list18 = res.data
              this.show18 = true
              break

            default:
              break
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.mrTop30 {
  margin-top: 30px;
}
.mrTop50 {
  margin-top: 50px;
}
.mrBot175 {
  margin-bottom: 175px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
